

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
/* MUI OVERRIDES */

/* flip arrows */
.MuiTablePagination-actions .MuiIconButton-root,
.MuiPickersArrowSwitcher-button {
  transform: rotateZ(180deg);
}
/* table */
/* .MuiDataGrid-columnSeparator {
  display: none !important;
} */

.progress-line{
  background: rgb(76, 175, 80);
  width: 100%;
  height: 8px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 16px rgba(138, 0, 212, 0.08);
}

.progress-line > div{
  width: 0;
  background: #a11f16;
  height: 100%;
}

progress[value] {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 10px;
  width: 100%;
  height: 10px;
}

@-webkit-keyframes animate-stripes {
  100% { background-position: -100px 0px; }
}

@keyframes animate-stripes {
  100% { background-position: -100px 0px; }
}
progress[value]::-webkit-progress-value {
  background-image:
-webkit-linear-gradient(top,
          rgba(212, 212, 212, 0.25),
 rgba(219, 219, 219, 0.25)),
-webkit-linear-gradient(left, #3CE680, #6EC1E4);
  background-size: 0px 20px, 100% 100%, 100% 100%;
}

.drawer-header-mobile > div,
.drawer-header-mobile{
  margin: 0;
  padding: 0 !important;
}

@media (max-width:765px) {
    .drawer-mobile {
        position: absolute;
    }
    .main-mobile{
      margin: 0 !important;
    }

    .drawer-header-mobile{
      
    }

    .drawer-header-mobile > div > div{
      margin: 10px 0 30px;
    }

    .drawer-header-mobile h2{
      font-size: 25px;
      position: absolute;
      top: 70px;
      right: 20px;
    }
}

.BudgetsPage > h2{
  margin-top: 0;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(0deg, #D527B7 33%, #ffffff  100%);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: white;
}
@keyframes rotation {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg)}
} 


@media screen and (max-width:765px) {
  .btn-mobile{
    display: flex;
    padding: 10px;
    border-radius: 5px;
    color: white;
    margin-bottom: 20px;
    cursor: pointer;
    background-color: rgb(76, 175, 80);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  }
}


@media screen and (max-width:765px) {
  .mobile-flex{
    display: flex;
    gap: 5px;
  }

  .mobile-gap:first-of-type{
    padding-top: 24px !important;
  }

  .mobile-gap{
    padding-top: 0 !important;
  }
}

.MuiDataGrid-root.MuiDataGrid-root--densityStandard {
  direction: ltr !important;
  transform: rotateY(180deg);
}

.MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
    transform: rotateY(180deg);
    color: #141414 !important;
}

.MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-columnHeaderTitle {
  transform: rotateY(180deg);
}

.MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiTablePagination-root{
  direction: rtl;
}

.MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-overlay,
.MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cellContent {
  transform: rotateY(180deg);
}

.MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell--withRenderer{
  text-align: right;
  justify-content: flex-end !important;
  transform: rotateY(180deg);
}

.MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-footerContainer {
  transform: rotateY(180deg);
}

.MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiButton-startIcon.MuiButton-iconSizeSmall {
  margin-left: -2px !important;
  margin-right: 8px !important;
}

.action-button {
  margin-right: 20px;
  /* background-color: #007bff; */
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.action-button:hover {
  background-color: #9da0a3;
}

i8.MuiDataGrid-root .MuiDataGrid-columnHeader {
  display: none;
}

