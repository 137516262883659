#root {
  font-family: Ploni, Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@font-face {
  font-family: "Ploni";
  src: url("/public/fonts/ploni/ploni-ultralight-aaa.woff")
    format("opentype");
  font-weight: 100;
}
@font-face {
  font-family: "Ploni";
  src: url("/public/fonts/ploni/ploni-light-aaa.woff")
    format("opentype");
  font-weight: 200;
}
@font-face {
  font-family: "Ploni";
  src: url("/public/fonts/ploni/ploni-regular-aaa.woff")
    format("opentype");
  font-weight: 300;
}
@font-face {
  font-family: "Ploni";
  src: url("/public/fonts/ploni/ploni-medium-aaa.woff")
    format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "Ploni";
  src: url("/public/fonts/ploni/ploni-demibold-aaa.woff")
    format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: "Ploni";
  src: url("/public/fonts/ploni/ploni-bold-aaa.woff")
    format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: "Ploni";
  src: url("/public/fonts/ploni/ploni-ultrabold-aaa.woff")
    format("opentype");
  font-weight: 700;
}
@font-face {
  font-family: "Ploni";
  src: url("/public/fonts/ploni/ploni-black-aaa.woff")
    format("opentype");
  font-weight: 800;
}







/* FONTS */
/* @font-face {
  font-family: "Almoni";
  src: url("/public/fonts/almoni-neue/almoni-neue-thin-aaa.otf")
    format("opentype");
  font-weight: 100;
}
@font-face {
  font-family: "Almoni";
  src: url("/public/fonts/almoni-neue/almoni-neue-ultralight-aaa.otf")
    format("opentype");
  font-weight: 200;
}
@font-face {
  font-family: "Almoni";
  src: url("/public/fonts/almoni-neue/almoni-neue-light-aaa.otf")
    format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Almoni";
  src: url("/public/fonts/almoni-neue/almoni-neue-regular-aaa.otf")
    format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Almoni";
  src: url("/public/fonts/almoni-neue/almoni-neue-medium-aaa.otf")
    format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: "Almoni";
  src: url("/public/fonts/almoni-neue/almoni-neue-demibold-aaa.otf")
    format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: "Almoni";
  src: url("/public/fonts/almoni-neue/almoni-neue-bold-aaa.otf")
    format("opentype");
  font-weight: 700;
}
@font-face {
  font-family: "Almoni";
  src: url("/public/fonts/almoni-neue/almoni-neue-ultrabold-aaa.otf")
    format("opentype");
  font-weight: 800;
}
@font-face {
  font-family: "Almoni";
  src: url("/public/fonts/almoni-neue/almoni-neue-black-aaa.otf")
    format("opentype");
  font-weight: 900;
} */
